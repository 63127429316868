
<div class="background">

<div class="header">
  <div class="logobox">
    <img src="../assets/img/logo.svg" class="logo"/><br/>
    <img src="../assets/img/logotexto.svg" class="logotexto"/>
  </div>
</div>

<div class="hero">
  <h1 class="title">Consultorías y Soluciones Tecnológicas</h1>
  <!-- p>Somos un grupo de profesionales con años de experiencia en generar soluciones tecnológicas que permiten apoyar las necesidades de negocio alineando la disponibilidad tecnológica existente.</p -->

  <div class="card">
    <i class="fa fa-share-alt icon"></i>
    <h2 class="title">Colaboración</h2>
    <p class="description">La colaboración entre el negocio y los equipos de tecnología es clave para desarrollar soluciones efectivas</p>
  </div>
  <div class="card">
    <i class="fa fa-robot icon"></i>
    <h2 class="title">Automatización</h2>
    <p class="description">Proveer de una operación automatizada reduce los costos de implementación y permite a los equipos detectar fallas a tiempo.</p>
  </div>
  <div class="card">
    <i class="fa fa-bullseye icon"></i>
    <h2 class="title">Procesos</h2>
    <p class="description">Entendemos que para que una solución sea efectiva requiere que esté dentro del contexto de un proceso definido y adoptado</p>
  </div>

</div>


<div class="service">
  <h1>Nuestros Servicios</h1>
  <!-- <p>Nuestros servicios están diseñados para pequeñas y medianas empresas que desean optimizar su rendimiento.</p> -->
  <div class="serviceContainer">





    <div class="rightcard">
      <i class="fa fa-file-code icon"></i>
      <div>
      <h2 class="title">Software Factory</h2>
      <p class="description">Células de trabajo ágil, interdisciplinaria, diseñado para abordar productos y/o proyectos enfocado en diseño de productos, experiencia de usuario, interfaces e integración de servicios.</p>
      </div>
    </div>

    <div class="leftcard">
      <i class="fa fa-cloud icon"></i>
      <div>
      <h2 class="title">DevOps</h2>
      <p class="description">Porque se necesita que la tecnología sea parte de la solución y soporte los entregables de negocio lo más rápido posible. Mantenemos las nubes a punto para sus soluciones.</p>
      </div>
    </div>

    <div class="rightcard">
      <i class="fas fa-layer-group icon"></i>
      <div>
      <h2 class="title">Arquitectura de Soluciones TI</h2>
      <p class="description">Servicio de consultoría de arquitectura tecnológica para diseñar o evaluar soluciones robustas, de calidad, escalables y de costos adecuados para el negocio.</p>
      </div>
    </div>

    <div class="leftcard">
      <i class="fa fa-fast-forward icon"></i>
      <div>
      <h2 class="title">Gestión Lean/Agile</h2>
      <p class="description">Orientados a generar valor al negocio. Gestión de proyectos basados en lean/agile y adaptandonos a la cultura de su empresa.</p>
      </div>
    </div>

   

    <div class="rightcard">
      <i class="fa fa-users icon"></i>
      <div>
      <h2 class="title">Outsourcing TI</h2>
      <p class="description">Ponemos a disposición profesionales y equipos de trabajo de manera ágil y oportuna, permitiendo de esta forma que nuestros clientes se enfoquen en lo esencial del negocio delegando la administración y la gestión de personas.</p>
      </div>
    </div>


   


  </div>

</div>

<!--
<div class="tecnologias">
  <h1>Tecnologías</h1>
  <p>Estamos en constante búsqueda, aprendizaje y adopción de tecnologías para nuestras soluciones</p>
  <div class="card">
    <img src="https://seeklogo.com/images/A/angular-logo-B76B1CDE98-seeklogo.com.png" alt="angular" class="logo"/>
    <img src="https://seeklogo.com/images/R/react-logo-7B3CE81517-seeklogo.com.png" alt="react" class="logo"/>
    <img src="https://seeklogo.com/images/G/gitlab-logo-757620E430-seeklogo.com.png" alt="gitlab" class="logo"/>
    <img src="https://seeklogo.com/images/D/docker-logo-6D6F987702-seeklogo.com.png" alt="docker" class="logo"/>
    <img src="https://seeklogo.com/images/K/kubernetes-logo-3A67038EAB-seeklogo.com.png" alt="kubernetes" class="logo"/>
    <img src="https://seeklogo.com/images/F/firebase-logo-402F407EE0-seeklogo.com.png" alt="firebase" class="logo"/>
    <img src="https://seeklogo.com/images/D/digital-ocean-logo-FBA954B5C9-seeklogo.com.png" alt="digital ocean" class="logo"/>
    <img src="https://seeklogo.com/images/G/google-cloud-logo-6B950E8ADB-seeklogo.com.png" alt="google cloud" class="logo"/>
  </div>
</div>
-->


<div class="footer">
  <!-- <div>
    <img src="../assets/img/logo.svg" class="logo"/>
  </div> -->
  <div class="contactinfo">
      <a href="mailto:info@itaum.com" target="_blank">
        <i class="fa fa-envelope icon"></i> info@itaum.com
      </a><br/>
      <i class="fa fa-phone icon"></i> +56 2 2869 5320<br/>
      <a href="https://goo.gl/maps/vmcX6RnjFEGepMut6" target="_blank" rel="noopener noreferrer">
        <i class="fas fa-search-location icon"></i> Av. Providencia 1208, of 207 - Providencia, Santiago, Chile
      </a>
  </div>
  <div class="extra">
    <img src="../assets/img/ambos_blanco.png" alt="Desafío 10x"/>
  </div>
</div>



</div>
